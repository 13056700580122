<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="600">
      <v-card>
        <v-card-title>
          {{ form.id ? 'Modifier un grossiste' : 'Ajouter un grossiste' }}
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-4">
          <form @submit.prevent="save">
            <v-row>
              <v-col cols="12" lg="6">
                <v-text-field id="key_26"
                              v-model="form.code"
                              :error-messages="errors.code"
                              :label="keyword.key_26"
                              hide-details="auto" @input="errors && errors.code ? errors.code = '' : false"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_27"
                              v-model="form.name"
                              :error-messages="errors.name"
                              :label="keyword.key_27"
                              hide-details="auto" @input="errors && errors.name ? errors.name = '' : false"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_1"
                              v-model="form.type"
                              :error-messages="errors.type"
                              :label="keyword.key_1"
                              hide-details="auto" @input="errors && errors.type ? errors.type = '' : false"/>
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field id="key_28"
                              v-model="form.phone"
                              :error-messages="errors.phone"
                              :label="keyword.key_28"
                              hide-details="auto" @input="errors && errors.phone ? errors.phone = '' : false"/>
              </v-col>

              <v-col cols="12" lg="6">
                <v-autocomplete id="key_3"
                                v-model="form.city"
                                :error-messages="errors.city"
                                :items="cities"
                                :label="keyword.key_3"
                                chips
                                deletable-chips
                                hide-details="auto" item-text="name"
                                item-value="name"
                                small-chips @input="errors && errors.city ? errors.city = '' : false">
                </v-autocomplete>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field id="key_40"
                              v-model="form.address"
                              :error-messages="errors.address"
                              :label="keyword.key_40"
                              hide-details="auto" @input="errors && errors.address ? errors.address = '' : false"/>
              </v-col>
              <v-col cols="12" lg="12">
                <v-text-field id="key_31"
                              v-model="form.landmark"
                              :error-messages="errors.landmark"
                              :label="keyword.key_31"
                              hide-details="auto" @input="errors && errors.landmark ? errors.landmark = '' : false"/>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field v-model="form.latitude"
                              :error-messages="errors.latitude"
                              hide-details="auto"
                              label="Latitude" @input="errors && errors.latitude ? errors.latitude = '' : false"/>
              </v-col>

              <v-col cols="12" lg="6">
                <v-text-field v-model="form.longitude"
                              :error-messages="errors.longitude"
                              hide-details="auto"
                              label="Longitude" @input="errors && errors.longitude ? errors.longitude = '' : false"/>
              </v-col>

            </v-row>
            <div class="d-flex justify-space-between mt-8">
              <v-spacer/>
              <v-btn :loading="btnLoading" class="rounded-lg" color="primary" depressed
                     type="submit">
                <v-icon left>mdi-content-save</v-icon>
                Enregistrer
              </v-btn>
            </div>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
  props: ['dialog'],
  data() {
    return {
      btnLoading: false,
      form: {},
      errors: {},
    }
  },
  methods: {
    close() {
      this.$emit('update:dialog', false)
    },
    editItem(item) {
      this.form = item
      this.errors = {}
    },
    addItem() {
      this.form = {}
      this.errors = {}
    },
    save() {
      this.btnLoading = true
      const url = this.form.id ? '/wholesalers/update' : '/wholesalers/store'
      HTTP.post(url, this.form).then(() => {
        this.$successMessage = 'Cet grossiste a été enregistrée avec succès'
        this.$emit('get-wholesalers')
        this.close()
        this.btnLoading = false
      }).catch(err => {
        this.btnLoading = false
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
        console.log(err)
      })
    },
  },
  computed: {
    keyword() {
      return this.$store.state.keyword
    },
    cities() {
      return this.$store.state.cities
    }
  }
}
</script>

<style scoped>

</style>