import { render, staticRenderFns } from "./WholesalerForm.vue?vue&type=template&id=daf61cc8&scoped=true&"
import script from "./WholesalerForm.vue?vue&type=script&lang=js&"
export * from "./WholesalerForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daf61cc8",
  null
  
)

export default component.exports